<template>
  <div class="w-full sm:w-8/12">
    <h2 class="mt-8 mb-5 mr-3 text-lg font-bold">Purchase History</h2>

    <div class="flex flex-col w-full mb-8 sm:w-9/12">
      <div class="duration-300">
        <datatable
          ref="table"
          :url="history.url"
          :ajax="true"
          :ajax-pagination="true"
          :columns="history.columns"
          :fillable="false"
          :loading="history.loading"
          :limit="15"
          :show-headings="false"
          :on-click="orderDetails"
          table-style="flush"
        />
      </div>
    </div>

    <modal className="w-11/12 sm:w-443px flex flex-col" ref="orderDetailsModal">
      <div class="sm:-mx-6">
        <h4 class="-mt-6 text-13 text-markeplace-blue">Payment reciept</h4>
        <div class="flex justify-center mt-6">
          <logo-round></logo-round>
        </div>
        <div class="flex flex-col mt-4 text-center text-marketplace-blue">
          <span class="px-6 font-normal leading-relaxed text-12 sm:px-12">
            {{ statusProgress() }}
          </span>
          <h2 class="mt-4 font-extrabold text-32">
            {{ orderAmount | formatAmount }}
          </h2>
        </div>

        <div class="mt-8 border-1 bg-marketplace-blue bg-opacity-85"></div>
        <h4 class="mt-5 text-13 text-markeplace-blue">Transaction details</h4>
        <div class="mt-2 order">
          <h1 class="order-text">Order amount</h1>
          <h1 class="order-text">
            {{ orderAmount | formatAmount }}
          </h1>
        </div>

        <div class="order">
          <h1 class="order-text">Transaction date</h1>
          <h1 class="order-text">{{ transactionDate }}</h1>
        </div>

        <div class="order">
          <h1 class="order-text">Reference number</h1>
          <h1 class="order-text">
            {{ reference }}
          </h1>
        </div>

        <div class="order">
          <h1 class="order-text">Merchant</h1>
          <h1 class="order-text">
            {{ merchantName }}
          </h1>
        </div>

        <h4 class="text-13 text-markeplace-blue mt-6">Branch Details</h4>
        <div class="order">
          <h1 class="order-text">Name</h1>
          <h1 class="order-text">
            {{ branchName | sentenceCase }}
          </h1>
        </div>
        <div class="order">
          <h1 class="order-text">Address</h1>
          <h1 class="order-text">
            {{ branchAddress }}
          </h1>
        </div>
        <div class="order">
          <h1 class="order-text">Phone Number</h1>
          <h1 class="order-text">
            {{ branchPhone }}
          </h1>
        </div>
        <div class="order" v-if="!loan">
          <h1 class="order-text">Account Details</h1>
          <h1 class="order-text">
            {{ branchAccountDetails }}
          </h1>
        </div>

        <div class="mt-8 text-center">
          <span
            class="text-sm underline cursor-pointer text-marketplace-dark"
            @click="print"
          >
            Print Receipt
          </span>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      history: this.$options.resource([], {
        url: `${this.$baseurl}/personal/users/transaction/transfer-purchases`,
        columns: [
          {
            name: 'description',
            th: 'Description',
            className: 'w-full whitespace-normal',
            render: order => `
                <div class="text-sm font-medium text-gray-700">${
                  order?.item
                }</div>
                <div class="text-xs text-gray-500">${this.$moment(
                  order?.created_at
                ).format('dddd, MMMM Do YYYY')}</div>
              `
          },
          {
            align: 'right',
            name: 'status',
            th: 'Status',
            render: order => {
              return `
                  <div class="text-sm font-medium text-gray-700">${this.$options.filters.formatAmount(
                    order?.loan?.amount ??
                      order?.transaction?.amount ??
                      order?.price
                  )}</div>
                  ${order?.merchant?.business_name}
                `;
            }
          }
        ]
      }),
      details: null,
      confirmOrderResource: this.$options.resource([])
    };
  },
  computed: {
    ...mapState(['banks']),
    transaction() {
      return this.details?.transaction;
    },
    branch() {
      return this.details?.branch;
    },
    branchName() {
      return this.branch?.name;
    },
    branchAddress() {
      return this.branch?.address;
    },
    branchPhone() {
      return this.branch?.phone;
    },
    loan() {
      return this.details?.loan;
    },
    branchAccountDetails() {
      const bankName = this.banks?.find(
        bank => bank.code === this.branch?.bank_name
      )?.name;
      return `${this.branch?.account_no} * ${bankName}`;
    },
    merchant() {
      return this.details?.merchant;
    },
    merchantName() {
      return this.merchant?.business_name;
    },
    reference() {
      return this.details?.order_no;
    },
    transactionDate() {
      return this.$moment(this.transaction?.created_at).format(
        'dddd, MMMM Do YYYY'
      );
    },
    orderAmount() {
      return (
        this.details?.loan?.amount ??
        this.transaction?.amount ??
        this.details?.price
      );
    },
    paymentStatus() {
      return this.details && this.details.status;
    },
    orderStatus() {
      return this.details && this.details?.order_status;
    }
  },
  methods: {
    async getOrderHistory() {
      this.history.setLoading();
      await this.sendRequest('personal.marketplace.orderHistory', {
        success: response => (this.history.data = response.data.data),
        error: error => console.log(error)
      });
      this.history.loading = false;
    },
    orderDetails(order) {
      this.details = { ...order };
      this.$refs.orderDetailsModal.open();
    },
    statusProgress() {
      switch (this.paymentStatus) {
        case 'pending':
          return `Your payment is currently processing`;
        case 'confirmed':
          return `Your payment has been confirmed`;
        case 'disbursed':
        case 'success':
          return `Your payment was successful and has been received by ${this.merchantName}.`;
        case 'failed':
        case 'cancelled':
          return `Payment failed`;
        default:
      }
    },
    print() {
      window.print();
    }
  }
};
</script>
<style lang="scss" scoped>
.order {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-marketplace-blue;
  @apply mt-8;
  &-text {
    @apply text-11;
    @apply font-normal;
  }
}
</style>
